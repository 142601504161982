import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get, findIndex } from 'lodash'

import PageLayout from '../components/page-layout'
import { SectionContact } from '../components/section'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import {
  formatAcfImageForARYImage,
  formatWpImageForARYImage,
} from '../lib/_helpers'

import { openOverlay } from '../redux/actions'

class PageHouses extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, screenSize, overlay, location } = this.props

    return (
      <PageLayout
        seo={getSEOFromPage(get(data, 'page'))}
        location={location}
        showFooter={true}
      >
        <SectionContact />
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    overlay: ui.overlay,
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageHouses)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
    }
  }
`
